import React from "react";
import { graphql } from 'gatsby'
import Layout from "../shared/components/organisms/layout/layout";
import WeContent from "../components/organisms/we-content/we-content";
import useMobie from '../shared/hooks/useMobile'
import SEO from "../shared/components/atoms/seo/seo"
import '../shared/styles/index.scss';

const WePage = (props) => {

  // send active modal to layout
  const [modalActive, setModalActive] = React.useState(null);
  const isMobile = useMobie();
  const weInfo = props.data.contentfulPetysNosotros

  return (
    <Layout activeMenu={4} modalActive={modalActive}>
      <SEO
        title={weInfo.metaTitulo ? weInfo.metaTitulo : ''}
        description={weInfo.metaDescripcion ? weInfo?.metaDescripcion?.metaDescripcion : ''}
        slug={weInfo.slug ? weInfo.slug : ''}
        lang="ES"
      />
      <WeContent
        weInfo={weInfo}
        setModalActive={setModalActive}
        isMobile={isMobile}
      />
    </Layout>
  )
}

export const query = graphql`
  query NosotrosQuery {
    contentfulPetysNosotros {
      slug
      metaTitulo
      metaDescripcion {
        metaDescripcion
      }
      imagenGeneral {
        description
        title
        file {
          url
        }
      }
      descripcionGeneral {
        json
      }
    }
  }
`


export default WePage
