import React from 'react'
import BreadCrumb from '../../../shared/components/atoms/breadcrumb/breadcrumb'
import ImgNosotros from '../../../assets/images/nosotros.png'
import RichText from '../../../shared/components/atoms/rich-text/rich-text'
import './we-content.scss'

const WeContent = (props) => {

  const { weInfo, isMobile, setActiveModal } = props

  return (
    <section className="f-we-section">
      <div className="f-breadcrum-position"><BreadCrumb mobile={isMobile} /></div>

      <div className="f-we-section-content">

        <figure className="f-we-img">
          <img src={ImgNosotros} alt="imagen Nosotros" />
        </figure>

        <div className="f-we-content">
          
          <RichText
            className="f-description-body"
            jsonInfo={weInfo?.descripcionGeneral?.json}
          />

        </div>

      </div>
    </section>
  )
}

export default WeContent
